
//Terain 
import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import {AutoComplete} from 'primereact/autocomplete';
import { DataTable } from 'primereact/datatable';
import { Password } from 'primereact/password';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { FileUpload } from 'primereact/fileupload';
import { Dropdown } from 'primereact/dropdown';
import Api from '../../../service/api';
import { Rating } from 'primereact/rating';
import { Toolbar } from 'primereact/toolbar';
import { InputTextarea } from 'primereact/inputtextarea';
import { RadioButton } from 'primereact/radiobutton';
import { InputNumber } from 'primereact/inputnumber';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
const Terain  = () => {
    let emptyTerrain = {
        id: null,
        name: '',
        citie_id : '',
        image: '',
        address : '',
        created_at: '',
        updated_at: '',         
    };
    const [Terrain, setTerrain] = useState(emptyTerrain);
    const [Confirmermdp, setConfirmermdp] = useState(null);
    const [confirmationError, setConfirmationError] = useState(null);
    const [isAjoute, setAjout] = useState(false);
    const [dropdownValue, setDropdownValue] = useState(null);
    const [cities, setcities] = useState([]);
    const [address, setaddresss] = useState([]);

    const [image, setImage] = useState(null);
    const [Name, setName] = useState(false);
    const [isModifier, setisModifier] = useState(false);
    const [Terrains, setTerrains] = useState([]);
    const [productDialog, setProductDialog] = useState(false);
    const [deleteProductDialog, setDeleteProductDialog] = useState(false);
    const [deleteProductsDialog, setDeleteProductsDialog] = useState(false);
   
    const [selectedTerrains, setSelectedTerrain] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const toast = useRef(null);
    const dt = useRef(null);
    const api = new Api();
    const urlimage =api.UrlImage();

    const  getAllCity =()=>{
        api.getCities().then((res)=>{
       // console.log(res)
        setcities(res)   
    })
    

    }
    const  getAllterains =()=>{
        api.getTerains().then((res)=>{
       // console.log(res)
       // setcities(res)   
      setTerrains(res)
    })
    }

    useEffect(() => {
        getAllCity()
        getAllterains()
        // const productService = new ProductService();
        // productService.getProducts().then(data => setProducts(data));
    }, []);

 
 
    const hideDialog = () => {
        setSubmitted(false);
        setProductDialog(false);
        setAjout(false)
        setisModifier(false)
    }

    const hideDeleteProductDialog = () => {
        setDeleteProductDialog(false);
    }

    const hideDeleteProductsDialog = () => {
        setDeleteProductsDialog(false);
    }

    const saveProduct = () => {
        if (!dropdownValue) {
            toast.current.show({ severity: 'info', summary: 'Information', detail: 'Ville et vide ' });
            return; 
        }
    
        // console.log(dropdownValue.id)
         const citie_id = dropdownValue.id;
     
         if (!Name || !image  ) {
             toast.current.show({ severity: 'info', summary: 'Information', detail: 'Le nom et/ou image   sont vides' });
         }
        
         else {
             const terain = {
                 name: Name,
                 image: image,
                 citie_id:citie_id,
                 address:address

             };
          
             
            
             api.AjoutTerain(terain)
             .then((res) => {
               //console.log(res.message);
               setTerrain(emptyTerrain);
               setName('')
               setaddresss('')
               setImage(null)
                getAllterains()
                setAjout(false)
               toast.current.show({ severity: 'success', summary: 'Successful', detail: res.message, life: 3000 });
             })
             .catch((err) => {
               console.error("Une erreur s'est produite :", err);
               // Gérez l'erreur ici, par exemple, affichez un message d'erreur à l'utilisateur
               toast.current.show({ severity: 'info', summary: 'Info ', detail:'Un terrain avec le même nom existe déjà pour cette ville', life: 3000 });
 
             });
         }
     }
    const editProduct = (product) => {
        setAjout(false)
        setTerrain({ ...product });
        setName(product.name)
        setaddresss(product.address)
        const city = cities.find((citi) => citi.id === product.citie_id);
        setDropdownValue(city)
        //setAjout(true);
        setisModifier(true)
    }

    const confirmDeleteProduct = (product) => {
        setTerrain(product);
        setDeleteProductDialog(true);
    }

    const deleteProduct = () => {
        console.log(Terrain.id)
        api.SupprimerTerain(Terrain.id).then((res)=>{
         toast.current.show({ severity: 'success', summary: 'Successful', detail:res.message, life: 3000 });
         getAllCity();
         getAllterains();
         setDeleteProductDialog(false);
        }
        )
        .catch((err) => {
         console.error("Une erreur s'est produite :", err);
         //toast.current.show({ severity: 'info', summary: 'Info ', detail:'Ville introuvable', life: 3000 });
 
       });
    }

   


    const exportCSV = () => {
        dt.current.exportCSV();
    }

    const confirmDeleteSelected = () => {
        setDeleteProductsDialog(true);
    }

    const deleteSelectedProducts = () => {
        // let _products = products.filter(val => !selectedProducts.includes(val));
        // setProducts(_products);
        // setDeleteProductsDialog(false);
        // setSelectedProducts(null);
        toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Products Deleted', life: 3000 });
    }

  

    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _product = { ...Terrain };
        _product[`${name}`] = val;

        setTerrain(_product);
    }
    const Ajoute = ()=>{
        //freeInput()
       // setRefClient(Folder.referenceClient)
        //setModifier(false)
        setTerrain(emptyTerrain)
        setisModifier(false)
        setAjout((prevAjout) => !prevAjout);
        
       // setAjouteBut(true)
        
    }


    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    <Button label="Ajouter" icon="pi pi-plus" className="p-button-success mr-2"  onClick={Ajoute} />
                    
                    {/* <Button label="Supprimer" icon="pi pi-trash" className="p-button-danger" onClick={confirmDeleteSelected} disabled={!selectedTerrains || !selectedTerrains.length} /> */}
                </div>
            </React.Fragment>
        )
    }

    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                {/* <FileUpload mode="basic" accept="image/*" maxFileSize={1000000} label="Import" chooseLabel="Import" className="mr-2 inline-block" /> */}
                <Button label="Export" icon="pi pi-upload" className="p-button-help" onClick={exportCSV} />
            </React.Fragment>
        )
    }

  
 

    const statusBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Status</span>
                <span className={`product-badge status-${rowData.inventoryStatus.toLowerCase()}`}>{rowData.inventoryStatus}</span>
            </>
        )
    }

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-success mr-2" onClick={() => editProduct(rowData)} />
                <Button icon="pi pi-trash" className="p-button-rounded p-button-warning mt-2" onClick={() => confirmDeleteProduct(rowData)} />
            </div>
        );
    }

    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 className="m-0">Gestion des Terains</h5>
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Rechercher" />
            </span>
        </div>
    );

    const productDialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label="Save" icon="pi pi-check" className="p-button-text" onClick={saveProduct} />
        </>
    );
    const deleteProductDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteProductDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteProduct} />
        </>
    );
    const deleteProductsDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteProductsDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteSelectedProducts} />
        </>
    );
    const handleFileInput = (e) => {
        // handle validations
        const selectedFile = e.target.files[0];
        setImage(selectedFile)
       console.log(e.target.files[0])
    }

    const ImageBodyTemplate = (rowData) => {
        //console.log("rowData",rowData.image)
  
        //const Image =rowData.image;
        const imageUrl = `${urlimage}/${rowData.image}`;
        //console.log("imageUrl",imageUrl)


        const imageStyle = {
            display: 'block', // Pour centrer l'image horizontalement
            margin: '0 auto', // Pour centrer l'image horizontalement
            maxWidth: '85px', // Largeur maximale de l'image (ajustez selon vos besoins)
            maxHeight: '85px', // Hauteur maximale de l'image (ajustez selon vos besoins)
          };

          return (
              <>
                  <span > 
                     <img 
                     src={imageUrl} 
                     alt={rowData.image}
                     style={imageStyle} // Appliquer les styles à l'image
                      />
                  </span>
                
              </>
          )
    }
    const CitieBodyTemplate = (rowData) => {
        const citi_id = rowData.citie_id;
        const city = cities.find((citi) => citi.id === citi_id);
    
        return (
            <>
                <span>
                    {city ? city.name : 'Non spécifié'}
                </span>
            </>
        );
    };

    const saveProductModifier  =()=>{


        if (!Name ) {
            toast.current.show({ severity: 'info', summary: 'Information', detail: 'Le nom et/ou l\'image sont vides' });
        } else {
            const terrain = {
                name: Name,
                image: image,
            };
    
            if (dropdownValue) {
                const citie_id = dropdownValue.id;
                terrain.citie_id = citie_id;
            }
            if (image) {
                terrain.image= image;
            }
            

    
           // console.log("terrainnnnnnnnnn Modifier", terrain);
    
            // Remplacez cet appel par l'appel à votre API pour mettre à jour le terrain
            api.ModifierTerrain(terrain, Terrain.id) // Remplacez terrainId par l'ID du terrain que vous mettez à jour
                .then((res) => {
                    // Gérez la réponse réussie ici, par exemple, affichez un message de succès à l'utilisateur
                    toast.current.show({ severity: 'success', summary: 'Succès', detail: res.message, life: 3000 });
                    // Réinitialisez vos champs de formulaire si nécessaire
                    setName('');
                    setImage('');
                    getAllterains();
                    setDropdownValue(null)
                    setisModifier(false)

                })
                .catch((err) => {
                    console.error("Une erreur s'est produite :", err);
                    // Gérez l'erreur ici, par exemple, affichez un message d'erreur à l'utilisateur
                    toast.current.show({ severity: 'info', summary: 'Info', detail: 'Une erreur s\'est produite', life: 3000 });
                });
        }
       

    }

    const DateCreatioTemplate = (rowData) => {
        const created_at = rowData.created_at;
        const formattedDate = new Date(created_at).toISOString().slice(0, 19).replace('T', ' ');
        return (
            <>
                <span>
                   {formattedDate}
                </span>
            </>
        );
    }
    const MiseAjourTemplate = (rowData) => {
        const updated_at = rowData.updated_at;
        const formattedDate = new Date(updated_at).toISOString().slice(0, 19).replace('T', ' ');
    
        return (
            <>
                <span>
                {formattedDate}
                </span>
            </>
        );
    }
    

    return (
        <><br></br><br></br>
        <div className="grid">
             <div className="col-12">
                <div className="card">
                <Toast ref={toast} />
                    <Toolbar className="mb-4" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>
    
                    {isAjoute && (
                       <div className="card">
                       <div className="grid p-fluid mt-2">
                           <div className="field col-12 md:col-6">
                               <label htmlFor="text">Nom</label>
                               <AutoComplete
                                   id="text"
                                   field="name"
                                   value={Name}
                                   onChange={(e) => setName(e.target.value)}
                               />
                           </div>
                           <div className="field col-12 md:col-6">
                               <label htmlFor="text">Address</label>
                               <AutoComplete
                                   id="text"
                                   field="Address"
                                   value={address}
                                   onChange={(e) => setaddresss(e.target.value)}
                               />
                           </div>
                           <div className="field col-12 md:col-6">
                            <label htmlFor="text">Ville</label>
                             <Dropdown value={dropdownValue} onChange={(e) => setDropdownValue(e.value)}
                              options={cities} 
                              optionLabel="name" placeholder="Select" />
                           </div>
                           <div className="field col-12 md:col-6">
                               <label htmlFor="text">Image</label>
                               <br></br>
                               <input
                                type="file"
                                onChange={handleFileInput}
                                style={{
                                    border: '2px solid #ccc',        // Bordure
                                    borderRadius: '4px',             // Coins arrondis
                                    padding: '8px',                  // Espacement intérieur
                                    backgroundColor: '#f2f2f2',      // Couleur de fond
                                    color: '#333',                   // Couleur du texte
                                    fontSize: '12px',                // Taille de police
                                               // Largeur
                                    cursor: 'pointer',               // Curseur pointeur
                                }}
                                />
                           </div>  
                           </div>
                           <span style={{ marginLeft: 'auto', display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                       {/* <Button label="Ajouter" className="p-button-info mr-2 mb-2" />
                         &nbsp;&nbsp;
                         <Button label="Annuler" className="p-button-info mr-2 mb-2" /> */}
                         <Button label="Ajouter" icon="pi pi-check" className="p-button-text" onClick={saveProduct} />
                          <Button label="Annuler" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
                        </span>
                       </div>
                 
                   
                  )}
                   {isModifier && (
                       <div className="card">
                       <div className="grid p-fluid mt-2">
                           <div className="field col-12 md:col-6">
                               <label htmlFor="text">Nom</label>
                               <AutoComplete
                                   id="text"
                                   field="name"
                                   value={Name}
                                   onChange={(e) => setName(e.target.value)}
                               />
                                <div className="field col-12 md:col-6">
                               <label htmlFor="text">Address</label>
                               <AutoComplete
                                   id="text"
                                   field="Address"
                                   value={address}
                                   onChange={(e) => setaddresss(e.target.value)}
                               />
                           </div>
                           </div>
                           <div className="field col-12 md:col-6">
                            <label htmlFor="text">Ville</label>
                             <Dropdown value={dropdownValue} onChange={(e) => setDropdownValue(e.value)}
                              options={cities} 
                              optionLabel="name" placeholder="Select" />
                           </div>
                           <div className="field col-12 md:col-6">
                               <label htmlFor="text">Image</label>
                               <br></br>
                               <input
                                type="file"
                                onChange={handleFileInput}
                                style={{
                                    border: '2px solid #ccc',        // Bordure
                                    borderRadius: '4px',             // Coins arrondis
                                    padding: '8px',                  // Espacement intérieur
                                    backgroundColor: '#f2f2f2',      // Couleur de fond
                                    color: '#333',                   // Couleur du texte
                                    fontSize: '12px',                // Taille de police
                                               // Largeur
                                    cursor: 'pointer',               // Curseur pointeur
                                }}
                                />
                           </div>  
                           </div>
                           <span style={{ marginLeft: 'auto', display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                       {/* <Button label="Ajouter" className="p-button-info mr-2 mb-2" />
                         &nbsp;&nbsp;
                         <Button label="Annuler" className="p-button-info mr-2 mb-2" /> */}
                         <Button label="Ajouter" icon="pi pi-check" className="p-button-text" onClick={saveProductModifier} />
                          <Button label="Annuler" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
                        </span>
                       </div>
                 
            
                  )}
                    <DataTable ref={dt} value={Terrains} selection={selectedTerrains} onSelectionChange={(e) => setSelectedTerrain(e.value)}
                        dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]} className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Affichage de {first} à {last} sur un total de {totalRecords} Villes."
                        globalFilter={globalFilter} emptyMessage={
                            <div style={{ textAlign: "center", paddingTop: "2em" }}>
                                <img src={`assets/layout/images/logo1.png`} alt="No results found" />
                                <p style={{ fontWeight: "bold", marginTop: "1em" }}>Désolé, aucun résultat ne correspond à votre recherche…</p>
                            </div>
                        } header={header} responsiveLayout="scroll">
                        {/* <Column selectionMode="multiple" headerStyle={{ width: '3rem'}}></Column> */}
              
                        <Column field="image" header="Image" sortable  headerStyle={{ width: '14%', minWidth: '10rem' }} body={ImageBodyTemplate}></Column>
                        <Column field="name" header="Nom" sortable  headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                        <Column field="citie_id" header="ville" sortable headerStyle={{ width: '14%', minWidth: '15rem' }} body={CitieBodyTemplate}></Column>
                        <Column field="address" header="Adresse" sortable headerStyle={{ width: '14%', minWidth: '15rem' }} ></Column>

                        <Column field="created_at" header="Date de création" sortable headerStyle={{ width: '14%', minWidth: '15rem' }} body={DateCreatioTemplate}></Column>
                        <Column field="updated_at" header="Dernière mise à jour" sortable headerStyle={{ width: '14%', minWidth: '15rem' }} body={MiseAjourTemplate}></Column>
                        
                        

                        {/* <Column field="inventoryStatus" header="Status" body={statusBodyTemplate} sortable headerStyle={{ width: '14%', minWidth: '10rem' }}></Column> */}
                        <Column body={actionBodyTemplate}></Column>
                    </DataTable>

                   
                    <Dialog visible={deleteProductDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteProductDialogFooter} onHide={hideDeleteProductDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                            {Terrain && <span>Êtes-vous sûr de vouloir supprimer  <b>{Terrain.name}</b>?</span>}
                        </div>
                    </Dialog>

                    <Dialog visible={deleteProductsDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteProductsDialogFooter} onHide={hideDeleteProductsDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                            {Terrain && <span>Êtes-vous sûr de vouloir supprimer la sélection des villes ?</span>}
                        </div>
                    </Dialog>
                </div>
            </div>
        </div></>
    );
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(Terain, comparisonFn);


