import React from 'react';
import  image from './assets/logo.jpg';

export const AppFooter = (props) => {

    return (
        <div className="layout-footer">
            <img src={image} alt="Logo" height="20" className="mr-2" />
            by
            <span className="font-medium ml-2"><a href='https://soorcin.com/'>Soorcin</a></span>
            
        </div>
    );
}
