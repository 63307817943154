
//import { useRouter } from 'next/navigation';
//import { useRouter} from 'react-router-dom'

import React,  { useState,useRef }  from 'react';
import { Route, useLocation, Redirect  } from 'react-router-dom';
import { Checkbox } from 'primereact/checkbox';
import { Button } from 'primereact/button';
import { Password } from 'primereact/password';
//import { LayoutContext } from '../../../../layout/context/layoutcontext';
import { InputText } from 'primereact/inputtext';
import { classNames } from 'primereact/utils';
import  image from '../assets/logo.jpg';
import Api from '../service/api'
import { setUser } from "../helper";
import { Toast } from 'primereact/toast';
import {isAdmin } from "../helper";


const Login = () => {
    const [password, setPassword] = useState('');
    const [checked, setChecked] = useState(false);
    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState("");
    const api = new Api();
    const toast = useRef(null);


   // const { layoutConfig } = useContext(LayoutContext);

   //const router = useRouter();
    const containerClassName = classNames('surface-ground flex align-items-center justify-content-center min-h-screen min-w-screen overflow-hidden');

    function validateForm() {
        let valid = true;
    
        if (!email.trim()) {
          setEmailError("Veuillez entrer votre email");
          valid = false;
        } else if (!/\S+@\S+\.\S+/.test(email)) {
          setEmailError("Entrer un email valide");
          valid = false;
        } else {
          setEmailError("");
        }
    
        return valid;
      }
    const handleLogin = async (event) => {
        
               // window.location.href = '/' 
       const admin = {
        email:email,
        password: password
        }       
     if (validateForm()){ 
        api.Login(admin).then(response => {
            if (response.status === true) {
              
              setUser(JSON.stringify(response.data));
              console.log(response.data.user) 
              if(response.data.user.role === "admin"){
                  setEmail("")
                   window.location.href = '/'} 
              else{
                toast.current.show({ severity: 'info', summary: 'Information', detail: 'Vous n êtes pas un administrateur' });
 
              }
            } else {
              
             toast.current.show({ severity: 'error', summary: 'Error Message', detail: 'Email  ou mot de passe incorrect ', life: 3000 });
    
            }
          })
          .catch(error => {
            toast.current.show({ severity: 'error', summary: 'Error Message', detail: 'Email  ou mot de passe incorrect ', life: 3000 });
            console.error('Error:', error);
          });
       
     } 
      }
    return (
        <div className={containerClassName}   >
             <Toast ref={toast} />
             {isAdmin() ? (   
                 <Redirect to="/" />
                 ) :     
            <div className="flex flex-column align-items-center justify-content-center">
           
                <div
                    style={{
                        borderRadius: '56px',
                        padding: '0.3rem',
                        background: 'linear-gradient(180deg, #ffc93c 10%, rgba(33, 150, 243, 0) 30%)',
                        boxShadow: 'rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px',
                    }}
                >
                    <div className="w-full surface-card py-8 px-5 sm:px-8" style={{ borderRadius: '53px' }}>
                        <div className="text-center">
                            {/* <img src={{image}} alt="Image" height="50" className="mb-3" /> */}
                            <img src={image} alt="Image" height="70" className="mb-3" />

                            <div className="text-900 text-3xl font-medium mb-3"></div>
                            <span className="text-600 font-medium">Connectez-vous pour continuer</span>
                        </div>

                        <div>
                            <label htmlFor="email1" className="block text-900 text-xl font-medium mb-2">
                                Email
                            </label>
                            <InputText id="email1" type="text" placeholder="Email address" className="w-full md:w-30rem mb-5" 
                            style={{ padding: '1rem' }} 
                            onChange={(event) =>{ setEmail(event.target.value) ; setEmailError("")}} 
                            />
                            {emailError && (
                                    <p
                                    style={{
                                        color: "red",
                                        marginBottom: "13px",
                                        marginTop: "-15px",
                                        fontWeight: "bold",
                                        fontSize: "13px",
                                    }}
                                    className="danger"
                                    >
                                    {emailError}
                                    </p>
                                )}

                            <label htmlFor="Mot de passe" className="block text-900 font-medium text-xl mb-2">
                            Mot de passe
                            </label>
                            <Password inputId="password1" value={password} onChange={(e) => setPassword(e.target.value)} placeholder="Password" toggleMask className="w-full mb-5" inputClassName="w-full p-3 md:w-30rem"></Password>

                            <div className="flex align-items-center justify-content-between mb-5 gap-5">
                             
                            </div>
                            <Button label="Sign In" className="w-full p-3 text-xl" style={{backgroundColor:'#ffc93c', color : 'black',border: '1px solid black',}} onClick={handleLogin}></Button>
                        </div>
                    </div>
                </div>
            </div>
          }
        </div>
    );
};

export default Login;