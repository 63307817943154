

//Organisateur 
import { Dropdown } from 'primereact/dropdown';
import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import { DataTable } from 'primereact/datatable';
import { Password } from 'primereact/password';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { FileUpload } from 'primereact/fileupload';
import { Rating } from 'primereact/rating';
import { Toolbar } from 'primereact/toolbar';
import { InputTextarea } from 'primereact/inputtextarea';
import { RadioButton } from 'primereact/radiobutton';
import { InputNumber } from 'primereact/inputnumber';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import Api from '../../service/api'
import { Checkbox } from 'primereact/checkbox';


//import { ProductService } from '../service/ProductService';

const Organisateur = () => {
    const api = new Api();
    const urlimage =api.UrlImage();
    let emptyOrganisateur = {
        id: null,
        name: '',
        prename: '',
        age: '',
        email: '',
        phone: '',
        role: 'organisateur',
        created_at: '',
        updated_at: '',
        password:'',
        citie_id: 0,
        image :''
    };
    const [organisateur, setOrganisateur] = useState(emptyOrganisateur);
    const [Confirmermdp, setConfirmermdp] = useState(null);
    const [confirmationError, setConfirmationError] = useState(null);
    const [dropdownValue, setDropdownValue] = useState(null);
    const [isAjoute, setisAjoute] = useState(false);
    const [isModifier, setisModifier] = useState(false);
    const [checkboxValue, setCheckboxValue] = useState([]);
    const [image, setImage] = useState(null);


   const [organisateurs, setOrganisateurs] = useState(null);
    // const organisateurs = [
    //     {
    //       id: 1,
    //       name: "belhassan",
    //       prename: "Meryem",
    //       age: 21,
    //       email: "Meryem@gmail.com",
    //       email_verified_at: null,
    //       phone: "0678750531",
    //       password: "$2y$10$NJFRSZ74hkc5pnBnSzmlqOX8Oh2yDf.Nt5VYoagdL4Xi0TL4ZWg3e",
    //       citie_id: 1,
    //       role: "joueur",
    //       remember_token: "1|coyEMfN2fdtaudLfuARAo1YeQbz6BZdf51LDvFT3",
    //       created_at: "2023-09-05 11:06:17",
    //       updated_at: "2023-09-05 11:06:37"
    //     },
    //     // Ajoutez d'autres objets organisateurs ici...
    //   ];


    const [productDialog, setProductDialog] = useState(false);
    const [deleteProductDialog, setDeleteProductDialog] = useState(false);
    const [deleteProductsDialog, setDeleteProductsDialog] = useState(false);
    const [cities, setcities] = useState([]);

   
    const [selectedOrganisateurs, setSelectedOrganisateurs] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const toast = useRef(null);
    const dt = useRef(null);


    const  getAllOrganisateurs =()=>{
        api.getOrganisateurs(

        ).then((res)=>{
       // console.log(res)
       setOrganisateurs(res)   
    })

    } 

    const  getAllCity =()=>{
        api.getCities().then((res)=>{
       // console.log(res)
        setcities(res)   
    })
    

    }


    useEffect(() => {
        getAllOrganisateurs();
        getAllCity();
        
    }, []);
  
    const onCheckboxChange = (e) => {
        let selectedValue = [...checkboxValue];
        if (e.checked)
            selectedValue.push(e.value);
        else
            selectedValue.splice(selectedValue.indexOf(e.value), 1);

        setCheckboxValue(selectedValue);
    };
    
    const openNew = () => {
        setisModifier(false)
        setisAjoute(true)
        setOrganisateur(emptyOrganisateur);
        setSubmitted(false);
        setProductDialog(true);
    }

    const hideDialog = () => {
        setSubmitted(false);
        setProductDialog(false);
    }

    const hideDeleteProductDialog = () => {
        setDeleteProductDialog(false);
    }

    const hideDeleteProductsDialog = () => {
        setDeleteProductsDialog(false);
    }

    const saveProduct = () => {
        setSubmitted(true);
        if(organisateur.id){
            const Orga ={
                name: organisateur.name,
                prename: organisateur.prename,
                age: organisateur.age,
                email: organisateur.email,
                phone: organisateur.phone,
                citie_id:dropdownValue.id
                }
            if(organisateur.password)   
            Orga.password=organisateur.password;
            if(image)
            Orga.image=image;
           //console.log("Organisateur  Modifier",Orga)
           api.ModifierUser(Orga,organisateur.id)
           .then((res) => {
            
            getAllOrganisateurs();
            setProductDialog(false)
            setOrganisateur(emptyOrganisateur)

            toast.current.show({ severity: 'success', summary: 'Successful', detail: res.message, life: 3000 });
          })
          .catch((err) => {
            console.error("Une erreur s'est produite :", err);
            // Gérez l'erreur ici, par exemple, affichez un message d'erreur à l'utilisateur
           // toast.current.show({ severity: 'info', summary: 'Info ', detail:err.error, life: 3000 });
        });
    
         }
        else{

        const Orga ={
            name: organisateur.name,
            prename: organisateur.prename,
            age: organisateur.age,
            email: organisateur.email,
            phone: organisateur.phone,
            role: organisateur.role,
            password:organisateur.password,
            citie_id:dropdownValue.id
            }
         const img={}
          //console.log(Orga)
            api.AjoutOrga(Orga)
            .then((res) => {

                if(image){
                img.image=image;
                api.AjoutImage(img,res.id) .then((res) => {
                   // console.log("Imageee",res)
                })
                getAllOrganisateurs();
                }
              setDropdownValue(null)
             getAllOrganisateurs();
            setProductDialog(false)
            setOrganisateur(emptyOrganisateur)

             toast.current.show({ severity: 'success', summary: 'Successful', detail: res.message, life: 3000 });
           })
           .catch((err) => {
             console.error("Une erreur s'est produite :", err);
             // Gérez l'erreur ici, par exemple, affichez un message d'erreur à l'utilisateur
             toast.current.show({ severity: 'info', summary: 'Info ', detail:'L adresse e-mail a déjà été utilisée', life: 3000 });
            });
        }
    }

    const editProduct = (product) => {
        setisModifier(true)
        setisAjoute(false)
        setImage(null)
        setOrganisateur({ ...product });
        const city = cities.find((citi) => citi.id === product.citie_id);
        setDropdownValue(city)
        setProductDialog(true);
    }

    const confirmDeleteProduct = (product) => {
        setOrganisateur(product);
        setDeleteProductDialog(true);
    }

    const deleteProduct = () => {
       // console.log(organisateur.id)
        api.SupprimerUser(organisateur.id).then((res)=>{
         toast.current.show({ severity: 'success', summary: 'Successful', detail:res.message, life: 3000 });
        
         setOrganisateur(emptyOrganisateur)
         getAllOrganisateurs()
         setDeleteProductDialog(false);

        }
        )
        .catch((err) => {
         console.error("Une erreur s'est produite :", err);
         //toast.current.show({ severity: 'info', summary: 'Info ', detail:'Ville introuvable', life: 3000 });
 
       });
    }
   
    const ImageBodyTemplate = (rowData) => {
        //console.log("rowData",rowData.image)
  
        //const Image =rowData.image;
        // const imageUrl = `${urlimage}/${rowData.image}`;
        
        const imageUrl = rowData.image ? `${urlimage}/${rowData.image}` : `${urlimage}/user.png`;
        //console.log("imageUrl",imageUrl)


        const imageStyle = {
            display: 'block', // Pour centrer l'image horizontalement
            margin: '0 auto', // Pour centrer l'image horizontalement
            maxWidth: '70px', // Largeur maximale de l'image (ajustez selon vos besoins)
            maxHeight: '70px', // Hauteur maximale de l'image (ajustez selon vos besoins)
            borderRadius: '30%', // Pour créer la forme circulaire
            overflow: 'hidden',
          };

          return (
              <>
                  <span > 
                     <img 
                     src={imageUrl} 
                     alt={rowData.image}
                     style={imageStyle} // Appliquer les styles à l'image
                      />
                  </span>
                
              </>
          )
      }
      const handleFileInput = (e) => {
        // handle validations
        const selectedFile = e.target.files[0];
        setImage(selectedFile)
       console.log(e.target.files[0])
    }
   


    const exportCSV = () => {
        dt.current.exportCSV();
    }

    const confirmDeleteSelected = () => {
        setDeleteProductsDialog(true);
    }

    const deleteSelectedProducts = () => {
        //console.log(selectedOrganisateurs)
       
        const promises = selectedOrganisateurs.map(async (org) => {
            try {
                const res = await api.SupprimerUser(org.id);
                return await Promise.resolve(res.message);
            } catch (err) {
                console.error("Une erreur s'est produite :", err);
                return await Promise.reject(err);
            }
        });
    
        Promise.all(promises)
            .then((successMessages) => {
                // Toutes les suppressions ont réussi
                toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Toutes les suppressions ont réussi.', life: 3000 });
                setOrganisateur(emptyOrganisateur);
                getAllOrganisateurs();
                setDeleteProductDialog(false);
                setDeleteProductsDialog(false);
                setSelectedOrganisateurs(null);
            })
            .catch((error) => {
                // Au moins une suppression a échoué
                toast.current.show({ severity: 'error', summary: 'Erreur', detail: 'Certaines suppressions ont échoué.', life: 3000 });
            });

           
       
    }

    const onCategoryChange = (e) => {
        let _product = { ...organisateur };
        _product['category'] = e.value;
        setOrganisateur(_product);
    }

    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _product = { ...organisateur };
        _product[`${name}`] = val;

        setOrganisateur(_product);
    }

    const onInputNumberChange = (e, name) => {
        const val = e.value || 0;
        let _product = { ...organisateur};
        _product[`${name}`] = val;

        setOrganisateur(_product);
        console.log(_product)
    }

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    <Button label="Ajouter" icon="pi pi-plus" className="p-button-success mr-2" onClick={openNew} />
                    <Button label="Supprimer" icon="pi pi-trash" className="p-button-danger" onClick={confirmDeleteSelected} disabled={!selectedOrganisateurs || !selectedOrganisateurs.length} />
                </div>
            </React.Fragment>
        )
    }

    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                {/* <FileUpload mode="basic" accept="image/*" maxFileSize={1000000} label="Import" chooseLabel="Import" className="mr-2 inline-block" /> */}
                <Button label="Export" icon="pi pi-upload" className="p-button-help" onClick={exportCSV} />
            </React.Fragment>
        )
    }

  
 

    const statusBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Status</span>
                <span className={`product-badge status-${rowData.inventoryStatus.toLowerCase()}`}>{rowData.inventoryStatus}</span>
            </>
        )
    }

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-success mr-2" onClick={() => editProduct(rowData)} />
                <Button icon="pi pi-trash" className="p-button-rounded p-button-warning mt-2" onClick={() => confirmDeleteProduct(rowData)} />
            </div>
        );
    }

    const header = (

        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 className="m-0">Gestion des Organisateurs</h5>
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Rechercher" />
            </span>
        </div>
    );

    const productDialogFooter = (
        <>
             <Button label="Annuler" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
                          <Button label="Ajouter" icon="pi pi-check" className="p-button-text" onClick={saveProduct} />
        </>
    );
    const deleteProductDialogFooter = (
        <>
            <Button label="Non" icon="pi pi-times" className="p-button-text" onClick={hideDeleteProductDialog} />
            <Button label="Oui" icon="pi pi-check" className="p-button-text" onClick={deleteProduct} />
        </>
    );
    const deleteProductsDialogFooter = (
        <>
            <Button label="Non" icon="pi pi-times" className="p-button-text" onClick={hideDeleteProductsDialog} />
            <Button label="Oui" icon="pi pi-check" className="p-button-text" onClick={deleteSelectedProducts} />
        </>
    );
    const DateCreatioTemplate = (rowData) => {
        const created_at = rowData.created_at;
        const formattedDate = new Date(created_at).toISOString().slice(0, 19).replace('T', ' ');
        return (
            <>
                <span>
                   {formattedDate}
                </span>
            </>
        );
    }
    const MiseAjourTemplate = (rowData) => {
        const updated_at = rowData.updated_at;
        const formattedDate = new Date(updated_at).toISOString().slice(0, 19).replace('T', ' ');
    
        return (
            <>
                <span>
                {formattedDate}
                </span>
            </>
        );
    }
    const CitieBodyTemplate = (rowData) => {
        const citi_id = rowData.citie_id;
        const city = cities.find((citi) => citi.id === citi_id);
    
        return (
            <>
                <span>
                    {city ? city.name : 'Non spécifié'}
                </span>
            </>
        );
    };


    return (
        <div className="grid crud-demo">
            <div className="col-12">
                <div className="card">
                    <Toast ref={toast} />
                    <Toolbar className="mb-4" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>

                    <DataTable ref={dt} value={organisateurs} selection={selectedOrganisateurs} onSelectionChange={(e) => setSelectedOrganisateurs(e.value)}
                        dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]} className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Affichage de {first} à {last} sur un total de {totalRecords} Organisateurs."
                        globalFilter={globalFilter} emptyMessage={
                            <div style={{ textAlign: "center", paddingTop: "2em" }}>
                                <img src={`assets/layout/images/logo1.png`} alt="No results found" />
                                <p style={{ fontWeight: "bold", marginTop: "1em" }}>Désolé, aucun résultat ne correspond à votre recherche…</p>
                            </div>
                        } header={header} responsiveLayout="scroll">
                        <Column selectionMode="multiple" headerStyle={{ width: '3rem'}}></Column>
                        <Column field="image" header="Image" sortable  headerStyle={{ width: '14%', minWidth: '10rem' }} body={ImageBodyTemplate}></Column>
                        <Column field="name" header="Nom" sortable  headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                        <Column field="prename" header="Prénom" sortable  headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                        <Column field="age" header="Âge" sortable   headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                        <Column field="email" header="E-mail"  sortable headerStyle={{ width: '14%', minWidth: '8rem' }}></Column>
                        <Column field="phone" header="Numéro de téléphone" sortable  headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                        <Column field="citie_id" header="Ville" sortable  headerStyle={{ width: '14%', minWidth: '10rem' }} body={CitieBodyTemplate}></Column>
                        <Column field="created_at" header="Date de création" sortable headerStyle={{ width: '14%', minWidth: '10rem' }} body={DateCreatioTemplate} ></Column>
                        <Column field="updated_at" header="Dernière mise à jour" sortable headerStyle={{ width: '14%', minWidth: '10rem' }} body={MiseAjourTemplate}></Column>
                        {/* <Column field="inventoryStatus" header="Status" body={statusBodyTemplate} sortable headerStyle={{ width: '14%', minWidth: '10rem' }}></Column> */}
                        <Column body={actionBodyTemplate}></Column>
                    </DataTable>

                    <Dialog visible={productDialog} style={{ width: '450px' }} header="Détails" modal className="p-fluid" footer={productDialogFooter} onHide={hideDialog}>
                        {/* {product.image && <img src={`assets/demo/images/product/${product.image}`} alt={product.image} width="150" className="mt-0 mx-auto mb-5 block shadow-2" />} */}
                        <div className="field">
                            <label htmlFor="name">Nom</label>
                            <InputText id="name" value={organisateur.name} onChange={(e) => onInputChange(e, 'name')} required autoFocus className={classNames({ 'p-invalid': submitted && !organisateur.name })} />
                            {submitted && !organisateur.name && <small className="p-invalid" style={{color:'red'}}> Champs requis.</small>}
                        </div>
                        <div className="field">
                            <label htmlFor="prename">Prénom</label>
                            <InputText id="prename" value={organisateur.prename} onChange={(e) => onInputChange(e, 'prename')} required autoFocus rows={3} cols={20} />
                            {submitted && !organisateur.prename && <small className="p-invalid" style={{color:'red'}}>Champs requis.</small>}
                        </div>

                            <div className="field">
                                <label htmlFor="phone">Téléphone</label>
                                <InputText id="phone" value={organisateur.phone} onChange={(e) => onInputChange(e, 'phone')} />
                            </div>
                            <div className="field">
                                <label htmlFor="age">Âge</label>
                                <InputNumber id="age" value={organisateur.age} onChange={(e) => onInputNumberChange(e, 'age')}  />
                            </div>
                            <div className="field">
                                <label htmlFor="quantity">E-mail</label>
                                <InputText  type="email"
                                    id="email" value={organisateur.email} 
                                    onChange={(e) => onInputChange(e, 'email')} 
                                    required autoFocus className={classNames({ 'p-invalid': submitted && !organisateur.email})}
                                     />
                                     {submitted && !organisateur.prename&& <small className="p-invalid" style={{color:'red'}}>Champs requis</small>}
                            </div>
                            <div className="field">
                               <label htmlFor="text">Image</label>
                               <br></br>
                               <input
                                type="file"
                                onChange={handleFileInput}
                                style={{
                                    border: '2px solid #ccc',        // Bordure
                                    borderRadius: '4px',             // Coins arrondis
                                    padding: '8px',                  // Espacement intérieur
                                    backgroundColor: '#f2f2f2',      // Couleur de fond
                                    color: '#333',                   // Couleur du texte
                                    fontSize: '12px',                // Taille de police
                                               // Largeur
                                    cursor: 'pointer',               // Curseur pointeur
                                }}
                                />
                                </div>
                            <div className="field">
                            <label htmlFor="text">Ville</label>
                             <Dropdown value={dropdownValue} onChange={(e) => setDropdownValue(e.value)}
                              options={cities} 
                              optionLabel="name" placeholder="Select" />
                           </div>
                           {isAjoute &&
                            <div className="field">
                                <label htmlFor="password">Mot de passe</label>
                            <Password inputId="password" value={organisateur.password} onChange={(e) => onInputChange(e, 'password')}  placeholder="Mot de passe" toggleMask className="w-full mb-5" inputClassName="w-full p-3 md:w-30rem"></Password>
                          
                                <label htmlFor="password">Confirmer le mot de passe</label>
                        
                              <Password
                                    inputId="passwordConfirmation"
                                    value={Confirmermdp}
                                    onChange={(e) => {
                                        setConfirmermdp(e.target.value);
                                        if (e.target.value !== organisateur.password) {
                                            // Afficher un message d'erreur lorsque la confirmation du mot de passe ne correspond pas
                                            setConfirmationError(true);
                                        } else {
                                            setConfirmationError(false);
                                        }
                                    }}
                                    placeholder="Confirmer Mot de passe "
                                    toggleMask
                                    className={classNames({ 'p-invalid': confirmationError })}
                                    inputClassName="w-full p-3 md:w-30rem"
                                />
                                {confirmationError && <small className="p-invalid" style={{color:'red'}} >Les mots de passe ne correspondent pas.</small>}
                              
                                </div>
                                } 
                         {isModifier &&
                            <div className="field">
                                <div className="field-checkbox">
                                <Checkbox inputId="checkOption1" name="option" value="Chicago" checked={checkboxValue.indexOf('Chicago') !== -1} onChange={onCheckboxChange} />
                                <label htmlFor="checkOption1">Souhaitez-vous modifier le mot de passe pour cette organisation ?</label>
                               </div>

                               {checkboxValue.indexOf('Chicago') !== -1 && 
                                <><label htmlFor="password">Nouveaux mot de passe </label>
                                <Password inputId="password" value={organisateur.password} onChange={(e) => onInputChange(e, 'password')} placeholder="Mot de passe" toggleMask className="w-full mb-5" inputClassName="w-full p-3 md:w-30rem">
                                    </Password><label htmlFor="password">Confirmer le mot de passe</label><Password
                                            inputId="passwordConfirmation"
                                            value={Confirmermdp}
                                            onChange={(e) => {
                                                setConfirmermdp(e.target.value);
                                                if (e.target.value !== organisateur.password) {
                                                    // Afficher un message d'erreur lorsque la confirmation du mot de passe ne correspond pas
                                                    setConfirmationError(true);
                                                } else {
                                                    setConfirmationError(false);
                                                }
                                            } }
                                            placeholder="Confirmer Mot de passe "
                                            toggleMask
                                            className={classNames({ 'p-invalid': confirmationError })}
                                            inputClassName="w-full p-3 md:w-30rem" />
                                {confirmationError && <small className="p-invalid" style={{color:'red'}} >Les mots de passe ne correspondent pas.</small>}
                              </>
                              }
                         </div>
                         } 
                    </Dialog>

                    <Dialog visible={deleteProductDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteProductDialogFooter} onHide={hideDeleteProductDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                            {organisateur && <span>Êtes-vous sûr de vouloir supprimer  <b>{organisateur.name}</b>?</span>}
                        </div>
                    </Dialog>

                    <Dialog visible={deleteProductsDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteProductsDialogFooter} onHide={hideDeleteProductsDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                            {organisateur && <span>Êtes-vous sûr de vouloir supprimer la sélection d'organisateurs ?</span>}
                        </div>
                    </Dialog>
                </div>
            </div>
        </div>
    );
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(Organisateur, comparisonFn);