/*import 'react-app-polyfill/ie11';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
//import * as serviceWorker from './serviceWorker';
import { HashRouter } from 'react-router-dom'
import ScrollToTop from './ScrollToTop';

ReactDOM.render(
    <HashRouter>
        <ScrollToTop>
            <App></App>

        </ScrollToTop>
    </HashRouter>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
//serviceWorker.unregister(); */
import 'react-app-polyfill/ie11';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
//import * as serviceWorker from './serviceWorker';
import { HashRouter, Route, Switch,Redirect } from 'react-router-dom'
import ScrollToTop from './ScrollToTop';
import Login from './Auth/Login';
import {isAdmin } from "./helper";


{
  ReactDOM.render(
    <HashRouter>
      <ScrollToTop>
        <Switch>
         
          <Route path="/login" component={Login} />
          {isAdmin ? (
            <Route component={App} />
          ) : (
            <Redirect to="/login" />
          )}
        </Switch>
      </ScrollToTop>
    </HashRouter>,
    document.getElementById('root')
  );
}