import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import {AutoComplete} from 'primereact/autocomplete';
import { DataTable } from 'primereact/datatable';
import { Password } from 'primereact/password';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { FileUpload } from 'primereact/fileupload';
import Api from '../../../service/api'
import { Rating } from 'primereact/rating';
import { Toolbar } from 'primereact/toolbar';
import { InputTextarea } from 'primereact/inputtextarea';
import { RadioButton } from 'primereact/radiobutton';
import { InputNumber } from 'primereact/inputnumber';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
const Ville = () => {
    let emptyCities = {
        id: null,
        name: '',
        image: '',
        created_at: '',
        updated_at: '',         
    };
      const [citie, setCitie] = useState(emptyCities);
      const [Confirmermdp, setConfirmermdp] = useState(null);
      const [confirmationError, setConfirmationError] = useState(null);
      const [isAjoute, setAjout] = useState(false);
      const [Name, setName] = useState(false);
      const [isModifier, setisModifier] = useState(false);
      const [cities, setcities] = useState([]);
      const api = new Api();
      const urlimage =api.UrlImage();
    // const cities = [
    //     {
    //         id: 1,
    //         name: 'meknas',
    //         image: 'Image.npg',
    //         created_at: '2023-09-05 11:06:37',
    //         updated_at: '2023-09-05 11:06:37',   
    //     },
    //     // Ajoutez d'autres objets organisateurs ici...
    //   ];


    const [productDialog, setProductDialog] = useState(false);
    const [deleteProductDialog, setDeleteProductDialog] = useState(false);
    const [deleteProductsDialog, setDeleteProductsDialog] = useState(false);
    const [image, setImage] = useState(null);

    
    const [selectedCities, setSelectedCities] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const toast = useRef(null);
    const dt = useRef(null);


    const  getAllCity =()=>{
        api.getCities().then((res)=>{
       // console.log(res)
        setcities(res)   
    })

    } 

    useEffect(() => {
        getAllCity()
        
    }, []);

 
    const openNew = () => {
        setCitie(emptyCities);
        setSubmitted(false);
        setProductDialog(true);
    }
   

    const hideDialog = () => {
        setCitie(emptyCities);
        setName("")
        setAjout(false);
        setisModifier(false)
    }

    const hideDeleteProductDialog = () => {
        setDeleteProductDialog(false);
    }

    const hideDeleteProductsDialog = () => {
        setDeleteProductsDialog(false);
    }


    const saveProductModifier  =()=>{
        if (!Name) {
            // Affichez un toast d'information si l'une des valeurs est vide
            toast.current.show({ severity: 'info', summary: 'Information', detail: 'Le nom est vide' });
        }
        else{
            const citi = {
                name: Name,
                
            };
            if(image)
            citi.image=image;

            console.log("cityid",citie.id)
            console.log(citi)
            api.ModifierCitie(citi,citie.id)
            .then((res) => {
              //console.log(res.message);
              setImage(null)
               setName("");
               getAllCity()
               setisModifier(false)
               setAjout(false)
              toast.current.show({ severity: 'success', summary: 'Successful', detail: res.message, life: 3000 });
            })
            .catch((err) => {
              console.error("Une erreur s'est produite :", err);
              // Gérez l'erreur ici, par exemple, affichez un message d'erreur à l'utilisateur
              toast.current.show({ severity: 'info', summary: 'Info ', detail:'Une ville avec ce nom existe déjà', life: 3000 });

            });
    
           // console.log(citi);

        }

    }
    const saveProduct = () => {
       // const name = Nameame;
        //const image = image; // Assurez-vous de définir la valeur d'image correctement
    
        // Vérifiez si name ou image sont vides
        if (!Name || !image) {
            // Affichez un toast d'information si l'une des valeurs est vide
            toast.current.show({ severity: 'info', summary: 'Information', detail: 'Le nom et/ou image sont vides' });
        }
        else {
            // Si les deux valeurs ne sont pas vides, créez l'objet citi
            
            const citi = {
                name: Name,
                image: image
            };
            api.AjoutCitie(citi)
            .then((res) => {
              //console.log(res.message);
              setCitie(emptyCities);
              setImage(null)
              setName("");
              setAjout(false)
               getAllCity()
              toast.current.show({ severity: 'success', summary: 'Successful', detail: res.message, life: 3000 });
            })
            .catch((err) => {
              console.error("Une erreur s'est produite :", err);
              // Gérez l'erreur ici, par exemple, affichez un message d'erreur à l'utilisateur
              toast.current.show({ severity: 'info', summary: 'Info ', detail:'Une ville avec ce nom existe déjà', life: 3000 });

            });
    
            console.log(citi);
        }
    }

    const editProduct = (product) => {
        console.log(product)
        setName(product.name)
        setImage(null)
        setCitie({ ...product });
        setAjout(false)
        setisModifier(true);
    }

    const confirmDeleteProduct = (product) => {
        setCitie(product);
        setDeleteProductDialog(true);
    }

    const deleteProduct = () => {
      // console.log(citie.id)
       api.SupprimerCitie(citie.id).then((res)=>{
        toast.current.show({ severity: 'success', summary: 'Successful', detail:res.message, life: 3000 });
        getAllCity();
        setDeleteProductDialog(false);
       }
       )
       .catch((err) => {
        console.error("Une erreur s'est produite :", err);
        toast.current.show({ severity: 'info', summary: 'Info ', detail:'Cette ville est déjà affectée à un ou plusieurs terrains.', life: 3000 });

      });
        
    }

    const exportCSV = () => {
        dt.current.exportCSV();
    }

    const confirmDeleteSelected = () => {
        setDeleteProductsDialog(true);
    }

    const deleteSelectedProducts = () => {
        // let _products = products.filter(val => !selectedProducts.includes(val));
        // setProducts(_products);
        // setDeleteProductsDialog(false);
        // setSelectedProducts(null);
        toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Products Deleted', life: 3000 });
    }

  

    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _product = { ...citie };
        _product[`${name}`] = val;

        setCitie(_product);
    }
    const Ajoute = ()=>{
        //freeInput()
       // setRefClient(Folder.referenceClient)
        //setModifier(false)
        setCitie(emptyCities)
        setName("")
        setisModifier(false)
        setAjout((prevAjout) => !prevAjout);
       // setAjouteBut(true)
        
    }


    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    <Button label="Ajouter" icon="pi pi-plus" className="p-button-success mr-2"  onClick={Ajoute} />
                    
                    {/* <Button label="Supprimer" icon="pi pi-trash" className="p-button-danger" onClick={confirmDeleteSelected} disabled={!selectedCities || !selectedCities.length} /> */}
                </div>
            </React.Fragment>
        )
    }

    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                {/* <FileUpload mode="basic" accept="image/*" maxFileSize={1000000} label="Import" chooseLabel="Import" className="mr-2 inline-block" /> */}
                <Button label="Export" icon="pi pi-upload" className="p-button-help" onClick={exportCSV} />
            </React.Fragment>
        )
    }

  
 

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-success mr-2" onClick={() => editProduct(rowData)} />
                <Button icon="pi pi-trash" className="p-button-rounded p-button-warning mt-2" onClick={() => confirmDeleteProduct(rowData)} />
            </div>
        );
    }

    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 className="m-0">Gestion des Villes</h5>
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Rechercher" />
            </span>
        </div>
    );

    const productDialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label="Save" icon="pi pi-check" className="p-button-text" onClick={saveProduct} />
        </>
    );
    const deleteProductDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteProductDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteProduct} />
        </>
    );
    const deleteProductsDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteProductsDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteSelectedProducts} />
        </>
    );

    const ImageBodyTemplate = (rowData) => {
        //console.log("rowData",rowData.image)
  
        //const Image =rowData.image;
        const imageUrl = `${urlimage}/${rowData.image}`;
        //console.log("imageUrl",imageUrl)


        const imageStyle = {
            display: 'block', // Pour centrer l'image horizontalement
            margin: '0 auto', // Pour centrer l'image horizontalement
            maxWidth: '85px', // Largeur maximale de l'image (ajustez selon vos besoins)
            maxHeight: '85px', // Hauteur maximale de l'image (ajustez selon vos besoins)
          };

          return (
              <>
                  <span > 
                     <img 
                     src={imageUrl} 
                     alt={rowData.image}
                     style={imageStyle} // Appliquer les styles à l'image
                      />
                  </span>
                
              </>
          )
      }

      
    const handleFileInput = (e) => {
        // handle validations
        const selectedFile = e.target.files[0];
        setImage(selectedFile)
       console.log(e.target.files[0])
    }

    const DateCreatioTemplate = (rowData) => {
        const created_at = rowData.created_at;
        const formattedDate = new Date(created_at).toISOString().slice(0, 19).replace('T', ' ');
        return (
            <>
                <span>
                   {formattedDate}
                </span>
            </>
        );
    }
    const MiseAjourTemplate = (rowData) => {
        const updated_at = rowData.updated_at;
        const formattedDate = new Date(updated_at).toISOString().slice(0, 19).replace('T', ' ');
    
        return (
            <>
                <span>
                {formattedDate}
                </span>
            </>
        );
    }

    return (
        <><br></br><br></br>
        <div className="grid">
             <div className="col-12">
                <div className="card">
                <Toast ref={toast} />
                    <Toolbar className="mb-4" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>
    
                    {isAjoute && (
                       <div className="card">
                       <div className="grid p-fluid mt-2">
                           <div className="field col-12 md:col-6">
                               <label htmlFor="text">Nom</label>
                               <AutoComplete
                                   id="text"
                                   field="name"
                                   value={Name}
                                   onChange={(e) => setName(e.target.value)}
                               />
                           </div>
                           <div className="field col-12 md:col-6">
                               <label htmlFor="text">Image</label>
                               <br></br>
                               <input
                                type="file"
                                onChange={handleFileInput}
                                style={{
                                    border: '2px solid #ccc',        // Bordure
                                    borderRadius: '4px',             // Coins arrondis
                                    padding: '8px',                  // Espacement intérieur
                                    backgroundColor: '#f2f2f2',      // Couleur de fond
                                    color: '#333',                   // Couleur du texte
                                    fontSize: '12px',                // Taille de police
                                               // Largeur
                                    cursor: 'pointer',               // Curseur pointeur
                                }}
                                />
                               {/* <FileUpload 
                               type="file"
                               id="file"
                               mode="basic"  
                               // name="demo[]" 
                              // url="./upload.php" accept="image/*" 
                              // maxFileSize={1000000}
                              onChange={onUpload} /> */}
                           </div>
                       </div>
                       <span style={{ marginLeft: 'auto', display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                       {/* <Button label="Ajouter" className="p-button-info mr-2 mb-2" />
                         &nbsp;&nbsp;
                         <Button label="Annuler" className="p-button-info mr-2 mb-2" /> */}
                         <Button label="Ajouter" icon="pi pi-check" className="p-button-text" onClick={saveProduct} />
                          <Button label="Annuler" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
                        </span>
                   </div>
                   
                  )}
                   {isModifier && (
                       <div className="card">
                       <div className="grid p-fluid mt-2">
                           <div className="field col-12 md:col-6">
                               <label htmlFor="text">Nom</label>
                               <AutoComplete
                                   id="text"
                                   field="name"
                                   value={Name}
                                   onChange={(e) => setName(e.target.value)}
                               />
                           </div>
                           <div className="field col-12 md:col-6">
                               <label htmlFor="text">Image</label>
                               <br></br>
                               <input
                                type="file"
                                onChange={handleFileInput}
                                style={{
                                    border: '2px solid #ccc',        // Bordure
                                    borderRadius: '4px',             // Coins arrondis
                                    padding: '8px',                  // Espacement intérieur
                                    backgroundColor: '#f2f2f2',      // Couleur de fond
                                    color: '#333',                   // Couleur du texte
                                    fontSize: '12px',                // Taille de police
                                               // Largeur
                                    cursor: 'pointer',               // Curseur pointeur
                                }}
                                />
                               {/* <FileUpload 
                               type="file"
                               id="file"
                               mode="basic"  
                               // name="demo[]" 
                              // url="./upload.php" accept="image/*" 
                              // maxFileSize={1000000}
                              onChange={onUpload} /> */}
                           </div>
                       </div>
                       <span style={{ marginLeft: 'auto', display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                       {/* <Button label="Ajouter" className="p-button-info mr-2 mb-2" />
                         &nbsp;&nbsp;
                         <Button label="Annuler" className="p-button-info mr-2 mb-2" /> */}
                         <Button label="Ajouter" icon="pi pi-check" className="p-button-text" onClick={saveProductModifier} />
                          <Button label="Annuler" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
                        </span>
                   </div>
                   
                  )}
                    <DataTable ref={dt} value={cities} selection={selectedCities} onSelectionChange={(e) => setSelectedCities(e.value)}
                        dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]} className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Affichage de {first} à {last} sur un total de {totalRecords} Terains."
                        globalFilter={globalFilter} emptyMessage={
                            <div style={{ textAlign: "center", paddingTop: "2em" }}>
                                <img src={`assets/layout/images/logo1.png`} alt="No results found" />
                                <p style={{ fontWeight: "bold", marginTop: "1em" }}>Désolé, aucun résultat ne correspond à votre recherche…</p>
                            </div>
                        } header={header} responsiveLayout="scroll">
                        {/* <Column selectionMode="multiple" headerStyle={{ width: '3rem'}}></Column> */}
                        <Column field="name" header="Nom" sortable  headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                        <Column field="image" header="Image" sortable  headerStyle={{ width: '14%', minWidth: '10rem' }} body={ImageBodyTemplate}></Column>
                        <Column field="created_at" header="Date de création" sortable headerStyle={{ width: '14%', minWidth: '15rem' }} body={DateCreatioTemplate}></Column>
                        <Column field="updated_at" header="Dernière mise à jour" sortable headerStyle={{ width: '14%', minWidth: '15rem' }} body={MiseAjourTemplate}></Column>
                        {/* <Column field="inventoryStatus" header="Status" body={statusBodyTemplate} sortable headerStyle={{ width: '14%', minWidth: '10rem' }}></Column> */}
                        <Column body={actionBodyTemplate}></Column>
                    </DataTable>

                   
                    <Dialog visible={deleteProductDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteProductDialogFooter} onHide={hideDeleteProductDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                            {citie && <span>Êtes-vous sûr de vouloir supprimer  <b>{citie.name}</b>?</span>}
                        </div>
                    </Dialog>

                    <Dialog visible={deleteProductsDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteProductsDialogFooter} onHide={hideDeleteProductsDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                            {citie && <span>Êtes-vous sûr de vouloir supprimer la sélection des villes ?</span>}
                        </div>
                    </Dialog>
                </div>
            </div>
        </div></>
    );
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(Ville, comparisonFn);
