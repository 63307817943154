
//Organisateur 
import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import { DataTable } from 'primereact/datatable';
import { Password } from 'primereact/password';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { FileUpload } from 'primereact/fileupload';
import { Rating } from 'primereact/rating';
import { Toolbar } from 'primereact/toolbar';
import { InputTextarea } from 'primereact/inputtextarea';
import { RadioButton } from 'primereact/radiobutton';
import { InputNumber } from 'primereact/inputnumber';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
//import { ProductService } from '../service/ProductService';
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
import Api from '../../service/api';


const Match = () => {
    
    let emptyMatch = {
        id: null,
        Terrain_id: '',
        organisateur_id: '',
        date_start: '',
        date_end: '',
        created_at: '',
        updated_at: '',
        
    };
    const [match, setmatch] = useState(emptyMatch);
    const [Confirmermdp, setConfirmermdp] = useState(null);
    const [confirmationError, setConfirmationError] = useState(null);
    const [dropdownTerain, setDropdownTerain] = useState(null);
    const [dropdownOrganisateur, setDropdownOrganisateur] = useState(null);

    const [Organisateurs, setOrganisateurs] = useState(null);

    const [Terrains, setTerrains] = useState([]);
    const [Matchs, setMatchs] = useState([]);
    const api = new Api();
    const urlimage =api.UrlImage();
    
    const  getAllterains =()=>{
        api.getTerains().then((res)=>{
       
      setTerrains(res)
    })
    }
    const  getAllMatchs =()=>{
        api.getMatchs()
        .then((res)=>{
            setMatchs(res)
          })
    }
    const  getAllOrganisateurs =()=>{
        api.getOrganisateurs(

        ).then((res)=>{
       // console.log(res)
       setOrganisateurs(res)   
    })

    } 
    useEffect(() => {
        getAllOrganisateurs()
        getAllterains()
        getAllMatchs()
        console.log(match)
       
    }, []);
   


    //const [matchs, setMatchs] = useState(null);
    const matchs = [
        {
            id: 1,
            Terrain_id: 'TerainX', 
            organisateur_id: "Meryem",
            date_start: '2023-10-06 18:06:17',
            date_end: '2023-10-06 19:06:39',
            created_at: "2023-09-05 11:06:17",
            updated_at: "2023-09-05 11:06:37"
        },
        // Ajoutez d'autres objets matchs ici...
      ];

    const [calendarValueStart, setCalendarValueStart] = useState(null);
    const [calendarValueEnd, setCalendarValueEnd] = useState(null);

    const [productDialog, setProductDialog] = useState(false);
    const [deleteProductDialog, setDeleteProductDialog] = useState(false);
    const [deleteProductsDialog, setDeleteProductsDialog] = useState(false);
   
   
    const [selectedmatchs, setSelectedmatchs] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const toast = useRef(null);
    const dt = useRef(null);

    useEffect(() => {
        // const productService = new ProductService();
        // productService.getProducts().then(data => setProducts(data));
    }, []);

    const formatCurrency = (value) => {
        return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
    }

    const openNew = () => {
        setmatch(emptyMatch);
        setSubmitted(false);
        setProductDialog(true);
    }

    const freeData=()=>{
        setDropdownOrganisateur(null)
        setCalendarValueEnd(null)
        setCalendarValueStart(null)
        setDropdownTerain(null)
        setmatch(emptyMatch)
    }

    const hideDialog = () => {
        setSubmitted(false);
        freeData()
        setProductDialog(false)
    }

    const hideDeleteProductDialog = () => {
        setDeleteProductDialog(false);
    }

    const hideDeleteProductsDialog = () => {
        setDeleteProductsDialog(false);
    }
  
    const saveProduct = () => {
        //setSubmitted(true);
        if(!match.id){
        if (!dropdownTerain) {
            toast.current.show({ severity: 'info', summary: 'Information', detail: 'Terain et vide ' });
            return; 
        }
       // const dateEnd = convertTODate(calendarValueStart);
       // const dateStart = convertTODate(calendarValueEnd)
        const Terrain_id = dropdownTerain.id
        const organisateur_id = dropdownOrganisateur.id
        const match = {
            date_start: calendarValueStart,
            date_end : calendarValueEnd,
            Terrain_id :Terrain_id,
            organisateur_id:organisateur_id
         }
         // Convertir les dates en format MySQL DATETIME
            const formattedStartDate = new Date(match.date_start).toISOString().slice(0, 19).replace('T', ' ');
            const formattedEndDate = new Date(match.date_end).toISOString().slice(0, 19).replace('T', ' ');

            match.date_start = formattedStartDate;
            match.date_end = formattedEndDate;

         console.log(match)
        api.AjoutMatch(match)
       .then((res) => {
        //console.log(res.message);
        // setTerrain(emptyTerrain);
        
        freeData();
         getAllterains()
         getAllMatchs()
         setProductDialog(false)

        toast.current.show({ severity: 'success', summary: 'Successful', detail: res.message, life: 3000 });
      })
      .catch((err) => {
        console.error("Une erreur s'est produite :", err);
        // Gérez l'erreur ici, par exemple, affichez un message d'erreur à l'utilisateur
       // toast.current.show({ severity: 'info', summary: 'Info ', detail:'Une ville avec ce nom existe déjà', life: 3000 });

      });
     }
     else{

        const matchUp= { 
            //organisateur_id:16
        }
        if (dropdownTerain) {
            matchUp.Terrain_id = dropdownTerain.id
        }
        if (dropdownOrganisateur) {
            matchUp.organisateur_id = dropdownOrganisateur.id
        }
        if(calendarValueStart){
           // date_end : calendarValueEnd,
           // const formattedStartDate = new Date(calendarValueStart).toISOString().slice(0, 19).replace('T', ' ');

            matchUp.date_start = calendarValueStart;
           
        }
        if(calendarValueEnd){
             //const formattedEndDate = new Date(calendarValueEnd).toISOString().slice(0, 19).replace('T', ' '); 
             matchUp.date_end = calendarValueEnd;
         }
         console.log("matchUp",matchUp)
         api.ModifierMatch(matchUp,match.id)
         
         .then((res) => {
             freeData();
             getAllterains()
             getAllMatchs()
             setProductDialog(false)
    
            toast.current.show({ severity: 'success', summary: 'Successful', detail: res.message, life: 3000 });
          })
          .catch((err) => {
            console.error("Une erreur s'est produite :", err);
            
          });

     }
    }

    const editProduct = (product) => {
        setmatch({ ...product });
        console.log(product)
        const terain = Terrains.find((terain)=> terain.id= product.Terrain_id)
        setDropdownTerain(terain)
        setCalendarValueEnd(product.date_end)
        setCalendarValueStart(product.date_start)
        setProductDialog(true);

    }

    const confirmDeleteProduct = (product) => {
        setmatch(product);
        setDeleteProductDialog(true);
    }

    const deleteProduct = () => {
        console.log(match.id)
        api.SupprimerMatch(match.id).then((res)=>{
         toast.current.show({ severity: 'success', summary: 'Successful', detail:res.message, life: 3000 });
         getAllMatchs();
         setDeleteProductDialog(false);

        }
        )
        .catch((err) => {
         console.error("Une erreur s'est produite :", err);
         //toast.current.show({ severity: 'info', summary: 'Info ', detail:'Ville introuvable', life: 3000 });
 
       });
    }

   


    const exportCSV = () => {
        dt.current.exportCSV();
    }

    const confirmDeleteSelected = () => {
        setDeleteProductsDialog(true);
    }

    const deleteSelectedProducts = () => {
        // let _products = products.filter(val => !selectedProducts.includes(val));
        // setProducts(_products);
        // setDeleteProductsDialog(false);
        // setSelectedProducts(null);
        toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Products Deleted', life: 3000 });
    }

    const onCategoryChange = (e) => {
        let _product = { ...match };
        _product['category'] = e.value;
        setmatch(_product);
    }

    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _product = { ...match };
        _product[`${name}`] = val;

        setmatch(_product);
    }

 

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    <Button label="Ajouter" icon="pi pi-plus" className="p-button-success mr-2" onClick={openNew} />
                    {/* <Button label="Supprimer" icon="pi pi-trash" className="p-button-danger" onClick={confirmDeleteSelected} disabled={!selectedmatchs || !selectedmatchs.length} /> */}
                </div>
            </React.Fragment>
        )
    }

    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                {/* <FileUpload mode="basic" accept="image/*" maxFileSize={1000000} label="Import" chooseLabel="Import" className="mr-2 inline-block" /> */}
                <Button label="Export" icon="pi pi-upload" className="p-button-help" onClick={exportCSV} />
            </React.Fragment>
        )
    }

  
 

    const statusBodyTemplate = (rowData) => {
        const terain_id = rowData.Terrain_id;
        const terain = Terrains.find((terain) => terain.id === terain_id);
    
        return (
            <>
                <span>
                    {terain ? terain.name : 'Non spécifié'}
                </span>
            </>
        );
    }
const orga = (id) => {
    if (Organisateurs) {
        const organi = Organisateurs.find((orga) => orga.id === id);
        return organi ? organi.name : 'Non spécifié';
    } else {
        // Gérer le cas où Organisateurs est null ou undefined
        return 'Non spécifié';
    }
}
    
const OrganisateurBodyTemplate = (rowData) => {
        const organisateur_id = rowData.organisateur_id;
       const organi = Organisateurs.find((orga) => orga.id === organisateur_id );
    
        return (
            <>
                <span>
                    {organi ? organi.name : 'Non spécifié'}
                   
                </span>
            </>
        );
    }
    const DateCreatioTemplate = (rowData) => {
        const created_at = rowData.created_at;
        const formattedDate = new Date(created_at).toISOString().slice(0, 19).replace('T', ' ');
        return (
            <>
                <span>
                   {formattedDate}
                </span>
            </>
        );
    }
    const MiseAjourTemplate = (rowData) => {
        const updated_at = rowData.updated_at;
        const formattedDate = new Date(updated_at).toISOString().slice(0, 19).replace('T', ' ');
    
        return (
            <>
                <span>
                {formattedDate}
                </span>
            </>
        );
    }

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-success mr-2" onClick={() => editProduct(rowData)} />
                <Button icon="pi pi-trash" className="p-button-rounded p-button-warning mt-2" onClick={() => confirmDeleteProduct(rowData)} />
            </div>
        );
    }

    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 className="m-0">Gestion des Matchs</h5>
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Rechercher" />
            </span>
        </div>
    );

    const productDialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label="Save" icon="pi pi-check" className="p-button-text" onClick={saveProduct} />
        </>
    );
    const deleteProductDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteProductDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteProduct} />
        </>
    );
    const deleteProductsDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteProductsDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteSelectedProducts} />
        </>
    );

    return (
        <div className="grid crud-demo">
            <div className="col-12">
                <div className="card">
                    <Toast ref={toast} />
                    <Toolbar className="mb-4" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>

                    <DataTable ref={dt} value={Matchs} selection={selectedmatchs} onSelectionChange={(e) => setSelectedmatchs(e.value)}
                        dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]} className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Affichage de {first} à {last} sur un total de {totalRecords} Matchs."
                        globalFilter={globalFilter} emptyMessage={
                            <div style={{ textAlign: "center", paddingTop: "2em" }}>
                                <img src={`assets/layout/images/logo1.png`} alt="No results found" />
                                <p style={{ fontWeight: "bold", marginTop: "1em" }}>Désolé, aucun résultat ne correspond à votre recherche…</p>
                            </div>
                        } header={header} responsiveLayout="scroll">
        
                        {/* <Column selectionMode="multiple" headerStyle={{ width: '3rem'}}></Column> */}
                        <Column field="Terrain_id" header="Terrain" sortable  headerStyle={{ width: '14%', minWidth: '10rem' }} body={statusBodyTemplate}></Column>
                        <Column field="organisateur_id" header="Organisateur" sortable  headerStyle={{ width: '14%', minWidth: '10rem' }} body={OrganisateurBodyTemplate}></Column>
                        <Column field="date_start" header="Date de début" sortable   headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                        <Column field="date_end" header="Date de fin" sortable   headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                        <Column field="created_at" header="Date de création" sortable headerStyle={{ width: '14%', minWidth: '10rem' }} body={DateCreatioTemplate}></Column>
                        <Column field="updated_at" header="Dernière mise à jour" sortable headerStyle={{ width: '14%', minWidth: '10rem' }} body={MiseAjourTemplate}></Column>
                        {/* <Column field="inventoryStatus" header="Status" body={statusBodyTemplate} sortable headerStyle={{ width: '14%', minWidth: '10rem' }}></Column> */}
                        <Column body={actionBodyTemplate}></Column>
                    </DataTable>
                    <Dialog visible={productDialog} style={{ width: '450px' }} header="Détails" modal className="p-fluid" footer={productDialogFooter} onHide={hideDialog}>
                        <div className="field">
                            <label htmlFor="name">Terrain</label>
                            <Dropdown value={dropdownTerain} onChange={(e) => setDropdownTerain(e.value)} options={Terrains} optionLabel="name" placeholder="Select" />

                        </div>
                        <div className="field">
                            <label htmlFor="prename">Organisateur</label>
<Dropdown value={dropdownOrganisateur} onChange={(e) => setDropdownOrganisateur(e.value)} options={Organisateurs} optionLabel="name" placeholder={orga(match.organisateur_id)} />
                        </div>

                            <div className="field">
                                <label htmlFor="phone">Date de début </label>
                                <InputText id="phone"  value={calendarValueStart} onChange={(e) => {setCalendarValueStart(e.target.value); console.log(e.target.value)}} placeholder={match.date_start} />

                                {/* <Calendar showIcon showButtonBar  showTime showSeconds  
                                 value={calendarValueStart} onChange={(e) => setCalendarValueStart(e.value)} placeholder={match.date_start}></Calendar>             */}
                         </div>
                            <div className="field">
                                <label htmlFor="age">Date de fin</label>
                                <InputText id="phone"  
                                value={calendarValueEnd} onChange={(e) => setCalendarValueEnd(e.target.value)} placeholder={match.date_end} />

                                {/* <Calendar showIcon showButtonBar showTime showSeconds  
                                value={calendarValueEnd} onChange={(e) => setCalendarValueEnd(e.value)} placeholder={match.date_end}></Calendar> */}
                          </div>
                            
                        
                    </Dialog>

                    <Dialog visible={deleteProductDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteProductDialogFooter} onHide={hideDeleteProductDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                            {match && <span>Êtes-vous sûr de vouloir supprimer  <b>{match.id} </b>?</span>}
                        </div>
                    </Dialog>

                    <Dialog visible={deleteProductsDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteProductsDialogFooter} onHide={hideDeleteProductsDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                            {match && <span>Êtes-vous sûr de vouloir supprimer la sélection d'matchs ?</span>}
                        </div>
                    </Dialog>
                </div>
            </div>
        </div>
    );
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(Match, comparisonFn);
