import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Route, useHistory, useLocation } from 'react-router-dom';
import Terain from './Menu/Terain';
import Ville from './Menu/Ville';
import { TabMenu } from 'primereact/tabmenu';

const HomeStep = () => { // Change "homeStep" en "HomeStep"
    const history = useHistory();
    const [activeIndex, setActiveIndex] = useState(0);
    
    const wizardItems = [

        { label: 'Ville', command: () => history.push('/homeStep/Ville') },
        { label: 'Terrain', command: () => history.push('/homeStep/Terain') },
    ];
    useEffect(() => {
        history.push('/homeStep/Ville');
    }, []);

    return (
        <div className="grid">
            <div className="col-12 md:col-12">
                <div className="card card-w-title">
                    <h5>Gestion des Villes & Terrains</h5>
                    <TabMenu model={wizardItems} activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)} />
                    <Route exact path={'/homeStep/Ville'} component={Ville} />
                    <Route path={'/homeStep/Terain'} component={Terain} />
                </div>
            </div>
        </div>
    );
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(HomeStep, comparisonFn); // Change "homeStep" en "HomeStep"
