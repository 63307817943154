import React, { useState, useEffect, useRef } from 'react';
import { Chart } from 'primereact/chart';
import Api from '../service/api'
import { Splitter, SplitterPanel } from 'primereact/splitter';
import { Button } from 'primereact/button';
import { DataView } from 'primereact/dataview';
import { Rating } from 'primereact/rating';
import { OrderList } from 'primereact/orderlist';
import { ScrollPanel } from 'primereact/scrollpanel';
import { Divider } from 'primereact/divider';
import { Toast } from 'primereact/toast';


const Dashboard = (props) => {
   // const [products, setProducts] = useState(null);
    const api = new Api();
    const toast = useRef(null);
    const urlimage =api.UrlImage();
    const [nbrOrganisateurs, setNbrOrganisateurs] = useState(0);
    const [nbrJouers, setNbrJouers] = useState(0);
    const [nbrvilles, setNbrvilles] = useState(0);
    const [nbrterain, setNbrterains] = useState(0);
    const [nbrmatchs, setNbrmatchs] = useState(0);
    const [notes, setNotes] = useState([]);

   const getallnot = ()=>{
    api.getNotes().then((res)=>  setNotes(res))
   }
    useEffect(() => {
        api.getCities().then((res)=>  setNbrvilles(res.length))
        api.getInscriptions().then((res)=>  setNbrJouers(res.length))
        api.getOrganisateurs().then((res)=>  setNbrOrganisateurs(res.length))
        api.getTerains().then((res)=>  setNbrterains(res.length))
        api.getMatchs().then((res)=>  setNbrmatchs(res.length))
        getallnot();
        
       
    }, []);

    const [lineOptions, setLineOptions] = useState(null)

    const applyLightTheme = () => {
        const lineOptions = {
            plugins: {
                legend: {
                    labels: {
                        color: '#495057'
                    }
                }
            },
            scales: {
                x: {
                    ticks: {
                        color: '#495057'
                    },
                    grid: {
                        color: '#ebedef',
                    }
                },
                y: {
                    ticks: {
                        color: '#495057'
                    },
                    grid: {
                        color: '#ebedef',
                    }
                },
            }
        };

        setLineOptions(lineOptions)
    }

    

   

    useEffect(() => {
        if (props.colorMode === 'light') {
            applyLightTheme();
        } 
    }, [props.colorMode]);

    const formatCurrency = (value) => {
        return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
    };
    
    const polarData = {
        datasets: [{
            data: [
                nbrJouers,
                nbrOrganisateurs,
                nbrvilles,
                nbrterain,
                nbrmatchs
            ],
            backgroundColor: [
                "#FF6384",
                "#4BC0C0",
                "#FFCE56",
                "#E7E9ED",
                "#36A2EB"
            ],
            label: 'My dataset'
        }],
        labels: [
            
            "joueurs",
            "Organisateurs",
            "Villes",
            "Terrains",
            "Match"
        ]
    };
    
    const polarOptions = {
        plugins: {
            legend: {
                labels: {
                    color: '#495057'
                }
            }
        },
        scales: {
            r: {
                grid: {
                    color: '#ebedef'
                }
            }
        }
    };
    const handleDeleteNote = (note) => {
        // Utilisation de window.confirm pour afficher une boîte de confirmation
        const confirmation = window.confirm("Êtes-vous sûr de vouloir supprimer cette note ?");
      
        if (confirmation) {
          console.log("Suppression confirmée, note à supprimer :", note);
          // Appel à l'API pour supprimer la note ici
          api.SupprimerNote(note.id).then(()=>{
            getallnot();
            toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Note  Deleted', life: 3000 });

          })
        } else {
          console.log("Suppression annulée, note non supprimée.");
        }
      };

    const itemTemplate = (note,index) => {
        return (
            <div key={index}>
                <div className="flex flex-column align-items-center sm:align-items-start gap-3">
                <div className="text-xl font-bold text-400">{note.message}</div>
                <Rating value={note.stars} readOnly cancel={false}></Rating>
            </div><div className="flex sm:flex-column align-items-center sm:align-items-end gap-3 sm:gap-2">
                    <Button icon="pi pi-trash" className="p-button-rounded" onClick={() => handleDeleteNote(note)} ></Button>
                </div>
                </div>
        );
    };

    return (
        <div className="grid">
             <Toast ref={toast} />
            <div className="col-12 lg:col-6 xl:col-3">
                <div className="card mb-0">
                    <div className="flex justify-content-between mb-3">
                        <div>
                            <span className="block text-500 font-medium mb-3">nombre d’inscriptions</span>
                            <div className="text-900 font-medium text-xl">{nbrJouers}</div>
                        </div>
                        <div className="flex align-items-center justify-content-center bg-blue-100 border-round" style={{width: '2.5rem', height: '2.5rem'}}>
                            <i className="pi pi-users text-blue-500 text-xl"/>
                        </div>
                    </div>
                    {/* <span className="text-green-500 font-medium">24 new </span>
                    <span className="text-500">since last visit</span> */}
                </div>
            </div>
            <div className="col-12 lg:col-6 xl:col-3">
                <div className="card mb-0">
                    <div className="flex justify-content-between mb-3">
                        <div>
                            <span className="block text-500 font-medium mb-3">terrains & Ville</span>
                            <div className="text-900 font-medium text-xl">  {nbrterain} & {nbrvilles}</div>
                        </div>
                        <div className="flex align-items-center justify-content-center bg-orange-100 border-round" style={{width: '2.5rem', height: '2.5rem'}}>
                            <i className="pi pi-map-marker text-orange-500 text-xl"/>
                        </div>
                    </div>
                    {/* <span className="text-green-500 font-medium">%52+ </span>
                    <span className="text-500">depuis la semaine dernière</span> */}
                </div>
            </div>
            <div className="col-12 lg:col-6 xl:col-3">
                <div className="card mb-0">
                    <div className="flex justify-content-between mb-3">
                        <div>
                            <span className="block text-500 font-medium mb-3">Organisateurs</span>
                            <div className="text-900 font-medium text-xl">{nbrOrganisateurs}</div>
                        </div>
                        <div className="flex align-items-center justify-content-center bg-cyan-100 border-round" style={{width: '2.5rem', height: '2.5rem'}}>
                            <i className="pi pi-inbox text-cyan-500 text-xl"/>
                        </div>
                    </div>
                    {/* <span className="text-green-500 font-medium">520  </span>
                    <span className="text-500">newly registered</span> */}
                </div>
            </div>
            <div className="col-12 lg:col-6 xl:col-3">
                <div className="card mb-0">
                    <div className="flex justify-content-between mb-3">
                        <div>
                            <span className="block text-500 font-medium mb-3">nombre de matchs</span>
                            <div className="text-900 font-medium text-xl">{nbrmatchs} </div>
                        </div>
                        <div className="flex align-items-center justify-content-center bg-purple-100 border-round" style={{width: '2.5rem', height: '2.5rem'}}>
                            <i className="pi pi-comment text-purple-500 text-xl"/>
                        </div>
                    </div>
                    {/* <span className="text-green-500 font-medium">85 </span>
                    <span className="text-500">responded</span> */}
                </div>
            </div>
            <div className="col-6 xl:col-8">
            <div className="card flex flex-column align-items-center" >
                  
                    <Chart type="polarArea" data={polarData} options={polarOptions} style={{ width: '50%' }} />
                </div>
                </div>
                <div className="col-6 xl:col-4">
               <div className="card flex flex-column align-items-center">
               <ScrollPanel style={{ width: '100%', height: '300px' }} className="custombar1">
                  
                  <div className=" flex-column xl:flex-row xl:align-items-start p-4 gap-4">
                 
                         {notes.map((note, index) => (

                             <>
                            
                              <div className="col-12">
                                 {itemTemplate(note,index)}
                                 <Divider></Divider>
                              </div>
                           
                            </>
                         ))}
                         </div>
                  </ScrollPanel>
                   
                </div>
                </div>
           
           
             
            </div>
    );
}

const comparisonFn = function (prevProps, nextProps) {
    return (prevProps.location.pathname === nextProps.location.pathname) && (prevProps.colorMode === nextProps.colorMode);
};

export default React.memo(Dashboard, comparisonFn);
