import React ,{useRef} from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import image from './assets/logo.jpg'
import { logout } from "./helper";
import { useHistory } from 'react-router-dom';
import { Button } from 'primereact/button';
import { TieredMenu } from 'primereact/tieredmenu';

export const AppTopbar = (props) => {
    const history = useHistory();
    const menu = useRef(null);
    //const onLogout = props.onLogout


    const handleLogout = () => {
       // onLogout();
        logout(); // Appeler la fonction logout depuis le composant Helper
        history.push('/login'); // Rediriger vers la page de connexion
      };
    const items = [
        
       
        {
            label: 'Déconnexion',
            icon: 'pi pi-fw pi-power-off',
            command: handleLogout 
        }
    ]

    return (
        <div className="layout-topbar">
            <Link to="/" className="layout-topbar-logo">
                <img src={image} alt="logo"   style={{ marginLeft: '55px' }}/>
                <span>Kickster</span>
            </Link>

            <button type="button" className="p-link  layout-menu-button layout-topbar-button" onClick={props.onToggleMenuClick}>
                <i className="pi pi-bars"/>
            </button>

            <button type="button" className="p-link layout-topbar-menu-button layout-topbar-button" onClick={props.onMobileTopbarMenuClick}>
                <i className="pi pi-ellipsis-v" />
            </button>

            <ul className={classNames("layout-topbar-menu lg:flex origin-top", {'layout-topbar-menu-mobile-active': props.mobileTopbarMenuActive })}>
                <TieredMenu model={items} popup ref={menu} breakpoint="767px" />
             
             
                <Button label="" icon="pi pi-user" onClick={(e) => menu.current.toggle(e)} />
                </ul>
        </div>
    );
}
